import {
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faTriangleExclamation,
  faXmark,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Close, Description, Root, Title } from '@radix-ui/react-toast';

import { useToast } from './toast.hook';

const ICON_MAPPING = {
  error: faCircleExclamation,
  info: faCircleInfo,
  success: faCircleCheck,
  warning: faTriangleExclamation,
};

export type ToastProps = {
  id: string;
  title: string;
  type?: 'error' | 'info' | 'success' | 'warning';
};

export const Toast = ({ id, title, type }: ToastProps) => {
  const { removeToast } = useToast();
  const typeIcon = type ? ICON_MAPPING[type] : faCircleInfo;

  const handleOpenChange = () => {
    removeToast(id);
  };

  return (
    <Root
      data-type={type}
      className="flex items-center justify-between gap-3 rounded bg-cyan-50 p-4 text-cyan-700 shadow-md data-[type=error]:bg-red-50 data-[type=success]:bg-emerald-50 data-[type=warning]:bg-orange-50 data-[type=error]:text-red-700 data-[type=success]:text-emerald-700 data-[type=warning]:text-orange-700"
      onOpenChange={handleOpenChange}
    >
      <Title>
        <FontAwesomeIcon icon={typeIcon} className="text-2xl leading-none" />
      </Title>
      <Description className="grow">{title}</Description>
      <Close>
        <FontAwesomeIcon icon={faXmark} />
      </Close>
    </Root>
  );
};

export default Toast;
