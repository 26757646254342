export const STRIPE_PRODUCT_CODES = {
  PRO: 'pro',
  TEAM: 'team',
  ESSENTIAL: 'essential',
};

export type StripeProductCode =
  (typeof STRIPE_PRODUCT_CODES)[keyof typeof STRIPE_PRODUCT_CODES];

export const PRO_PRODUCT_CONST = {
  title: 'Pro',
  description: 'Advanced features for experts',
  features: [
    'Branding - your logo, your \n colors, your avatar',
    '3 active AI surveys',
    'Unlimited responses',
    'Unlimited seat licenses',
    'AI Interviews',
  ],
};

export const ESSENTIAL_PRODUCT_CONST = {
  title: 'Essential',
  description: 'Advanced features for experts',
  features: [
    'Own your data',
    'Export to XLSX',
    'Export to MD',
    'Export to JSON',
    'Export data for detailed analysis',
  ],
};