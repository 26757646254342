import { gql } from '../__generated__/gql';

export const GET_STRIPE_PRODUCTS = gql(/* GraphQL */ `
  query GetStripeProducts($filter: StripeProductFilterInput) {
    stripeProducts(filter: $filter) {
      products {
        id
        name
        description
        metadata {
          productCode
        }
        prices {
          id
          amount
          currency
          interval
        }
      }
    }
  }
`);
