import { useAuth } from '@workos-inc/authkit-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Redirecting from './auth.redirecting';

export default function SignUpPage() {
  const { signUp, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate(-1);
    } else {
      signUp({ state: { returnTo: 'home' } });
    }
  }, [signUp, user, navigate]);

  return <Redirecting />;
}
