import { useAuth } from '@workos-inc/authkit-react';
import { useEffect } from 'react';

import Redirecting from './auth.redirecting';

export default function SignOutPage() {
  const { signOut } = useAuth();

  useEffect(() => {
    signOut();

    if (
      ['evo.dev.theysaid.io', 'localhost'].includes(window.location.hostname)
    ) {
      window.location.href = '/login';
    } else {
      window.location.href = 'https://theysaid.io';
    }
  }, [signOut]);

  return <Redirecting />;
}
