import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
  Input,
} from '@/shared-ui';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useManagerOrganizationsCreateOrganization } from './manager-organizations-create-organization.hooks';

interface CreateOrganizationProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

const ManagerOrganizationsCreateOrganization = ({
  open,
  onOpenChange,
}: CreateOrganizationProps) => {
  const { closeButtonRef, formState, register, onSubmit } =
    useManagerOrganizationsCreateOrganization();

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent onInteractOutside={(e) => e.preventDefault()}>
        <form onSubmit={onSubmit}>
          <div className="flex gap-4 px-6 pb-4 pt-6">
            <div className="w-full space-y-2">
              <DialogTitle className="font-display text-2xl font-semibold leading-loose tracking-tight text-zinc-900">
                Add Workspace
              </DialogTitle>

              <DialogDescription className="sr-only">
                Create a new workspace for the current user
              </DialogDescription>

              <div className="space-y-1">
                <label
                  htmlFor="organization-name"
                  className="text-sm font-medium text-gray-900"
                >
                  Organization Name
                </label>
                <Input
                  id="organization-name"
                  {...register('name')}
                  type="text"
                />
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="organization-website"
                  className="text-sm font-medium text-gray-900"
                >
                  Organization website URL
                </label>
                <div className="flex">
                  <div className="text-muted-foreground flex items-center rounded-l-md border border-r-0  bg-gray-100 px-3 text-xs font-normal text-gray-500">
                    https://
                  </div>
                  <Input
                    id="organization-website"
                    {...register('website')}
                    type="text"
                    className="rounded-l-none focus:ring-0 focus-visible:ring-offset-0"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-end gap-2 border-t px-6 py-4">
            <DialogClose asChild>
              <Button
                ref={closeButtonRef}
                variant="secondary"
                disabled={formState.isSubmitting}
              >
                Cancel
              </Button>
            </DialogClose>

            <Button
              disabled={!formState.isValid || formState.isSubmitting}
              type="submit"
            >
              {formState.isSubmitting ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                'Confirm'
              )}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ManagerOrganizationsCreateOrganization;
