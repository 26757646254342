import { gql } from '../__generated__/gql';

export const ADD_QUESTION_V2 = gql(/* GraphQL */ `
  mutation AddQuestionV2($input: CreateQuestionV2Input!) {
    createQuestionV2(input: $input) {
      id
      type
      text
      required
      aiInsights
      assets {
        id
        name
        url
        description
        createdAt
        updatedAt
      }
      aiConvoDepth
      questionOrder
      multipleSelect
      optionRenderType
      randomize
      options {
        id
        label
        value
        assets {
          id
          name
          url
          description
          createdAt
          updatedAt
        }
      }
      tags {
        id
        name
        color
      }
    }
  }
`);
