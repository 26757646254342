import { cn } from '@/shared-ui';
import { ProjectResponseComponentProps } from './project-response-renderer.type';

interface ProjectResponseImageChoiceProps {
  options: NonNullable<ProjectResponseComponentProps['options']>;
  value: string;
  onClick: (value: string) => void;
  readonly?: boolean;
  multiselect?: boolean;
  assets?: ProjectResponseComponentProps['assets'];
}

export default function ProjectResponseImageChoice({
  options,
  value,
  onClick,
  readonly,
  multiselect,
}: ProjectResponseImageChoiceProps) {
  const isValueSelected = (selectedValue: string) =>
    multiselect
      ? value.split(',').includes(selectedValue)
      : value === selectedValue;

  const handleClick = (clickedValue: string) => {
    if (readonly) return;

    if (multiselect) {
      const currentValues = value ? value.split(',').filter(Boolean) : [];

      if (isValueSelected(clickedValue)) {
        // Remove value if already selected
        onClick(currentValues.filter((v) => v !== clickedValue).join(','));
      } else {
        // Add new value to existing selections
        onClick([...currentValues, clickedValue].join(','));
      }
    } else {
      // Single select just replaces the value
      onClick(clickedValue);
    }
  };

  return (
    <div className="ms-5 flex min-w-full flex-col gap-3">
      <div className="flex  flex-wrap items-start justify-start gap-2">
        {options.map((option) => {
          const selected = isValueSelected(option.value);
          const imageUrl = option.assets?.[0]?.url;

          return (
            <button
              key={option.value}
              onClick={() => handleClick(option.value)}
              disabled={readonly}
              className={cn(
                'w-72 flex-col items-start gap-2 overflow-hidden rounded-lg border pb-2 transition-all',
                'bg-gradient-to-r from-rose-50 to-indigo-50',
                selected && 'border-sky-500 ring-2 ring-sky-500',
                !selected && 'border-gray-200 hover:border-gray-300'
              )}
            >
              {imageUrl && (
                <div className="h-48 w-full p-2">
                  <img
                    src={imageUrl}
                    alt={option.label}
                    className="h-full w-full rounded object-contain"
                  />
                </div>
              )}
              <div className="w-full text-center text-base text-gray-900">
                {option.label}
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
}
