import { gql } from '../__generated__/gql';

export const ADD_PROJECT = gql(/* GraphQL */ `
  mutation AddProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      id
      title
      type
      published
      status
      redirectUrl
      thankyouText
      introText
      learningGoal
      askContactInformation
      isPoll
      questions {
        id
        type
        text
        required
        aiInsights
        questionOrder
        assets {
          id
          name
          url
          description
          createdAt
          updatedAt
        }
        tags {
          id
          name
          color
        }
        aiConvoDepth
        multipleSelect
        optionRenderType
        randomize
        options {
          id
          label
          value
          assets {
            id
            name
            url
            description
            createdAt
            updatedAt
          }
        }
      }
      tags {
        id
        name
        color
      }
    }
  }
`);
