import { forwardRef } from 'react';

import Button from './ui.button';
import { cn } from './ui.helpers';

interface Props extends Omit<React.ComponentProps<typeof Button>, 'variant'> {
  selected: boolean;
}

const GradientToggle = forwardRef<HTMLButtonElement, Props>(
  ({ className, selected, ...rest }, forwardedRef) => {
    return (
      <div
        data-state={selected ? 'selected' : undefined}
        className="rounded-[9px] p-[1px] data-[state=selected]:bg-gradient-to-r data-[state=selected]:from-rose-200/90 data-[state=selected]:to-indigo-200/90"
      >
        <Button
          className={cn(
            'bg-gray-50 from-rose-100/90 to-indigo-100/90 font-normal data-[state=selected]:border-0 data-[state=selected]:bg-gradient-to-r',
            className
          )}
          data-state={selected ? 'selected' : undefined}
          ref={forwardedRef}
          variant="outline"
          {...rest}
        />
      </div>
    );
  }
);

export default GradientToggle;
