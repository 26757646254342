import {
  GET_COMPANY_INFO,
  GET_INSIGHTS_V2_LIGHT_WEIGHT,
  UPDATE_COMPANY_INFO,
} from '@/manager-graphql';
import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useOnboarding } from './manager-onboarding.hooks';
import { useMeasurement } from '@/shared-measurement';

export function useSurveyIframeMessages(
  callback: (event: MessageEvent) => void
) {
  useEffect(() => {
    const handlePostMessage = (event: MessageEvent) => {
      callback(event);
    };
    window.addEventListener('message', handlePostMessage);
    return () => window.removeEventListener('message', handlePostMessage);
  }, [callback]);
}

export function useOnboardingSurvey() {
  const { companyInfo, isLoading: isCompanyInfoLoading } = useOnboarding();
  const measurement = useMeasurement();

  const { id: projectId = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const { data } = useQuery(GET_INSIGHTS_V2_LIGHT_WEIGHT, {
    variables: { filter: { projectId } },
    skip: !projectId,
  });
  const [updateCompanyInfo, { loading: updateLoading }] = useMutation(
    UPDATE_COMPANY_INFO,
    {
      refetchQueries: [GET_COMPANY_INFO],
      awaitRefetchQueries: true,
    }
  );

  useSurveyIframeMessages((event) => {
    if (event.data.type === 'insight-added') {
      setIsButtonEnabled(true);
    }
  });

  useEffect(() => {
    if (data?.insightsV2.items.length) {
      setIsButtonEnabled(true);
    }
  }, [data]);

  const handleButtonClick = useCallback(async () => {
    if (!companyInfo?.id) return;
    if (isButtonEnabled) {
      await updateCompanyInfo({
        variables: {
          input: { hasOnboarded: true, id: companyInfo.id },
        },
      });
      navigate('/projects');
      if (measurement) {
        measurement.stop_session_recording();
        measurement?.track("I'm done, let me in Clicked", {
          format: 'Onboarding completed',
        });
      }
    }
  }, [isButtonEnabled, navigate, companyInfo, updateCompanyInfo, measurement]);

  const getSurveyUrl = useCallback((id: string) => {
    const host = window.location.origin;
    return `${host}/survey/manager-embed/project/${id}`;
  }, []);

  return {
    surveyId: projectId,
    isButtonEnabled,
    handleButtonClick,
    getSurveyUrl,
    survey: location?.state,
    companyInfo,
    isCompanyInfoLoading,
    updateLoading,
  };
}
