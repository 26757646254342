import { gql } from '../__generated__/gql';

export const GENERATE_AI_QUESTIONS = gql(/* GraphQL */ `
  query GenerateAIQuestions($input: GenerateQuestionsInput!) {
    generateAIQuestions(input: $input) {
      text
      options {
        label
        value
      }
      tag
      type
    }
  }
`);