import { Chat } from '@/shared-ui';
import {
  MessageSender,
  MessageV2,
  OptionRenderType,
  QuestionType,
} from '@/survey-graphql';
import {
  getEmoji,
  RendererOption,
} from '@/survey-project-response-message-renderers';
import { ComponentProps } from 'react';

export const OPTIMISTIC_MESSAGE_TEMP_SENT_ID = 'temp-sent-id';
export const OPTIMISTIC_MESSAGE_TEMP_REPLY_ID = 'temp-reply-id';
export const V2_OPTIMISTIC_SENT_MESSAGE: MessageV2 = {
  id: OPTIMISTIC_MESSAGE_TEMP_SENT_ID,
  content: '',
  createdAt: new Date(),
  sender: MessageSender.User,
  optionRenderType: OptionRenderType.Emoji,
  assets: null,
  metadata: {
    isConversationOver: false,
    options: [],
    type: QuestionType.OpenEnded,
    required: true,
    multipleSelect: false,
  },
};
export const V2_OPTIMISTIC_REPLY_MESSAGE: MessageV2 = {
  id: OPTIMISTIC_MESSAGE_TEMP_REPLY_ID,
  content: '...',
  createdAt: new Date(),
  sender: MessageSender.Assistant,
  optionRenderType: OptionRenderType.Emoji,
  assets: null,
  metadata: {
    isConversationOver: false,
    options: [],
    type: QuestionType.OpenEnded,
    required: true,
    multipleSelect: false,
  },
};

const POLL_MESSAGE_UUID = '00000000-0000-0000-0000-000000000002';

export function getAnsweredValueForAiMessage(
  message: ComponentProps<typeof Chat>['messages'][number],
  arr: {
    sender: 'user' | 'assistant' | 'system';
    text: string;
    id: string;
  }[]
) {
  if (message.id === POLL_MESSAGE_UUID) {
    const firstUserResponseIndex = arr.findIndex((m) => m.sender === 'user');
    if (firstUserResponseIndex !== -1) {
      return arr[firstUserResponseIndex].text;
    }
  }
  let nextMessageIndex = arr.findIndex((m) => m.id === message.id) + 1;
  while (
    nextMessageIndex < arr.length &&
    (arr[nextMessageIndex].sender === 'assistant' ||
      arr[nextMessageIndex].sender === 'system')
  ) {
    nextMessageIndex++;
  }
  if (nextMessageIndex < arr.length) {
    const nextMessage = arr[nextMessageIndex];
    return nextMessage.text;
  }
  return null;
}

export function getRelatedAiMessageToUserAnswer(
  currentIndex: number,
  arr: {
    sender: 'user' | 'assistant' | 'system';
    text: string;
    id: string;
    type?: string;
    optionRenderType: OptionRenderType;
    options?: {
      label: string;
      value: string;
    }[];
    createdAt: Date;
  }[]
) {
  let previousMessageIndex = currentIndex - 1;
  while (
    previousMessageIndex >= 0 &&
    arr[previousMessageIndex].sender === 'user'
  ) {
    previousMessageIndex--;
  }
  if (previousMessageIndex >= 0) {
    return arr[previousMessageIndex];
  }
  return null;
}

export function getTypeOfRenderer(
  message: ComponentProps<typeof Chat>['messages'][number]
): RendererOption {
  const lowerCaseType = message.type?.toLowerCase();
  switch (lowerCaseType) {
    case 'multiplechoice':
      if (message.multipleSelect) {
        return 'multipleChoice';
      }
      return 'singleChoice';
    case 'rating':
      return 'rating';
    case 'ranking':
      return 'ranking';
    default:
      return 'openEnded';
  }
}

export const formatUserAnswer = (
  answerType: RendererOption,
  answer: string,
  possibleValues?: {
    label: string;
    value: string;
  }[],
  optionRenderType?: OptionRenderType
) => {
  switch (answerType) {
    case 'ranking':
      return answer
        .split(',')
        .map((value) =>
          String.fromCharCode(
            (possibleValues?.findIndex((e) => e.value === value) ?? 0) + 65
          )
        )
        .join(', ')
        .trim();
    case 'rating':
      return (function () {
        const biggestRate = possibleValues?.reduce(
          (max, curr) => (Number(curr.value) > max ? Number(curr.value) : max),
          -Infinity
        );
        if (optionRenderType === OptionRenderType.Emoji) {
          return `${getEmoji(Number(answer), possibleValues?.length ?? 0)}`;
        }
        if (optionRenderType === OptionRenderType.Star) {
          return '⭐'.repeat(Number(answer));
        }

        return `${answer} out of ${biggestRate}`;
      })();
    case 'multipleChoice':
      return (
        possibleValues
          ?.filter((o) =>
            answer
              .split(',')
              .map((v) => v.trim())
              .includes(o.value)
          )
          .map((o) => o.label)
          .join(', ') ?? ''
      );
    case 'singleChoice':
      return possibleValues?.find((o) => o.value === answer)?.label ?? '';
    default:
      return answer.trim();
  }
};

export const DEFAULT_RENDERER_TYPE = 'openEnded';
