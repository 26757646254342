export const ensureTripleBackticks = (str: string) => {
  // Check if the string contains triple backticks anywhere
  if (str.includes('```')) {
    // Find the first occurrence of triple backticks
    const firstTripleBackticksIndex = str.indexOf('```');

    // Find the last occurrence of triple backticks
    const lastTripleBackticksIndex = str.lastIndexOf('```');

    // Check if the first and last occurrence are the same (only one set of triple backticks)
    // or if the last occurrence is not at the end of the string
    if (
      firstTripleBackticksIndex === lastTripleBackticksIndex ||
      lastTripleBackticksIndex !== str.length - 3
    ) {
      // If so, add the ending triple backticks
      str += '```';
    }
  }
  return str;
};

export const POLL_MESSAGE_UUID = '00000000-0000-0000-0000-000000000002';
export const POLL_MESSAGE_UUID_LOADING = '00000000-0000-0000-0000-000000000003';
