import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { AuthKitProviderWithRedirect } from '@/manager-auth';
import { ApolloProviderWithClient } from '@/manager-graphql';
import { Upgrade } from '@/manager-upgrade';
import { MeasurementWithUser } from '@/shared-measurement';
import { RecoilDebugObserver, ToastProvider } from '@/shared-ui';
import ErrorBoundary from './error-boundary';
import AppRoutes from './routes';

export default function App() {
  return (
    <BrowserRouter>
      <AuthKitProviderWithRedirect
        clientId={import.meta.env.VITE_WORKOS_CLIENT_ID}
      >
        <RecoilRoot>
          {import.meta.env.VITE_APP_DEV && <RecoilDebugObserver />}
          <ApolloProviderWithClient apiHost={import.meta.env.VITE_API_HOST}>
            <ErrorBoundary>
              <MeasurementWithUser token={import.meta.env.VITE_MIXPANEL} />
              <Upgrade />
              <ToastProvider>
                <AppRoutes />
              </ToastProvider>
            </ErrorBoundary>
          </ApolloProviderWithClient>
        </RecoilRoot>
      </AuthKitProviderWithRedirect>
    </BrowserRouter>
  );
}
