import { z } from 'zod';

export const URL_PATTERN =
  /^(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?(\?.*)?$/;

export const createOrganizationFormSchema = z.object({
  name: z.string().trim().min(1, { message: 'Name is required' }),
  website: z
    .string()
    .transform((value) => {
      return value?.toLowerCase().startsWith('http')
        ? value
        : `https://${value}`;
    })
    .refine(
      (transformedValue) =>
        transformedValue === '' ||
        URL_PATTERN.test(transformedValue?.toLowerCase()),
      {
        message: 'Invalid url. Please enter a valid url.',
      }
    ),
});

export type CreateOrganizationFormValues = z.infer<
  typeof createOrganizationFormSchema
>;
