import { gql } from '../__generated__/gql';

export const EXPORT_INSIGHTS = gql(/* GraphQL */ `
  mutation ExportInsights($input: CreateProjectInsightsDownloadInput!) {
    exportInsights(input: $input) {
      id
      createdAt
      expiresAt
      size
      status
      url
    }
  }
`);
