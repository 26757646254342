import { OptionRenderType } from '@/manager-graphql';
import { cn, Tooltip } from '@/shared-ui';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProjectResponseAssetItem } from './project-response-asset-item';
import { renderValue } from './project-response-rating-scale-select.helpers';
import RatingScaleStarSelect from './project-response-rating-scale-star-select';
import { ProjectResponseComponentProps } from './project-response-renderer.type';

interface RatingScaleProps {
  options: {
    label: string;
    value: string;
  }[];
  value: string;
  onClick: (value: string) => void;
  readonly?: boolean;
  hideLabels?: boolean;
  className?: string;
  optionRenderType?: OptionRenderType;
  assets?: ProjectResponseComponentProps['assets'];
  onLabelChange?: (label: 'LOW' | 'HIGH', value: string) => void;
  optionsWrapperClassName?: string;
}

export default function RatingScaleSelect({
  options,
  value,
  onClick,
  readonly,
  className,
  hideLabels,
  optionRenderType,
  assets,
  onLabelChange,
  optionsWrapperClassName,
}: RatingScaleProps) {
  const sortedOptions = options.sort((a, b) => +a.value - +b.value);
  const labels: [string, string] = options?.length
    ? [options[0].label, options[options.length - 1].label]
    : ['', ''];

  if (optionRenderType === OptionRenderType.Star) {
    return (
      <RatingScaleStarSelect
        options={options}
        value={value}
        onClick={onClick}
        readonly={readonly}
        className={className}
        hideLabels={hideLabels}
        optionRenderType={optionRenderType}
        assets={assets}
      />
    );
  }

  const showWarnings = [!labels[0], !labels[1]];

  return (
    <div
      data-option-render-type={optionRenderType}
      className={cn(
        'data-[option-render-type!=Emoji]:ms-5 min-h-20 flex-col items-center justify-start gap-3 rounded-lg border border-gray-200 bg-white p-3 placeholder:flex',
        className
      )}
    >
      {assets && assets?.length > 0 && assets[0]?.url && (
        <ProjectResponseAssetItem asset={assets[0]} />
      )}

      <div
        data-hide-labels={hideLabels}
        className="flex flex-col items-start justify-start gap-1 self-stretch data-[hide-labels=false]:min-h-14"
      >
        <div
          data-option-render-type={optionRenderType}
          className={cn(
            'flex flex-wrap items-center justify-center gap-2 self-stretch data-[option-render-type=Emoji]:sm:flex-nowrap',
            optionsWrapperClassName
          )}
        >
          {sortedOptions.map((v) => (
            <div
              data-selected={value === v.value}
              data-option-render-type={optionRenderType}
              data-small={options.length > 5}
              className="flex h-10 min-h-10 w-16 min-w-9 flex-1 items-center justify-center rounded-[9px] bg-gradient-to-r from-rose-100 to-indigo-100 hover:from-rose-200 hover:to-indigo-200 active:from-rose-400 active:to-indigo-400 data-[option-render-type=Emoji]:h-12 data-[option-render-type=Emoji]:min-h-12 data-[small=true]:w-10 data-[small=true]:max-w-10 data-[selected=true]:from-rose-400 data-[selected=true]:to-indigo-400 md:w-24"
              key={v.value}
            >
              <button
                data-selected={value === v.value}
                className="mx-auto my-auto flex h-[calc(100%-2px)] w-[calc(100%-2px)] flex-col items-center justify-center gap-2 rounded-lg bg-gradient-to-r from-rose-50 to-indigo-50 p-2 hover:from-rose-100 hover:to-indigo-100 active:from-rose-100 active:to-indigo-100 active:ring-2 active:ring-sky-300 data-[selected=true]:from-rose-200 data-[selected=true]:to-indigo-200"
                onClick={() => onClick(v.value)}
                data-small={options.length > 5}
                disabled={readonly}
              >
                <div
                  data-option-render-type={optionRenderType}
                  className="text-center font-sans text-gray-900 data-[option-render-type=Emoji]:text-2xl data-[option-render-type=Star]:text-xl"
                >
                  {renderValue(v, optionRenderType, options)}
                </div>
              </button>
            </div>
          ))}
        </div>

        {!hideLabels && (
          <div className="flex items-start justify-between self-stretch">
            {labels.map((label, index) => (
              <div
                key={index}
                data-position={index === 0 ? 'low' : 'high'}
                className="group flex items-center gap-1 text-center font-sans text-xs text-gray-500 data-[position=high]:flex-row-reverse"
              >
                {showWarnings[index] && (
                  <Tooltip
                    content={
                      index === 0
                        ? 'Please enter a label for the lowest rating'
                        : 'Please enter a label for the highest rating'
                    }
                  >
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      className="text-orange-400"
                    />
                  </Tooltip>
                )}
                {!onLabelChange ? (
                  label
                ) : (
                  <input
                    placeholder={index === 0 ? 'very bad' : 'very good'}
                    className="w-min min-w-min appearance-none ring-0 group-data-[position=low]:text-left group-data-[position=high]:text-right"
                    value={label}
                    onChange={(e) =>
                      onLabelChange(
                        index === 0 ? 'LOW' : 'HIGH',
                        e.target.value
                      )
                    }
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
