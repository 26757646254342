import { useQuery } from '@apollo/client';

import { DashboardFilterInput, GET_COMPANY_INFO } from '@/manager-graphql';
import { useMemo } from 'react';

export const useOnboarding = (
  poll?: boolean,
  dashboardFilterInput?: DashboardFilterInput
) => {
  const untilDate = useMemo(() => new Date().toISOString(), []);
  const { data, loading, refetch, error } = useQuery(GET_COMPANY_INFO, {
    fetchPolicy: poll ? 'cache-and-network' : 'cache-first',
    pollInterval: poll ? 1000 : 0,
    errorPolicy: 'all',
    variables: {
      insightsCountInput: dashboardFilterInput ?? {
        since: new Date(0).toISOString(),
        until: untilDate,
      },
    },
  });

  return {
    companyInfo: data?.companyInfo,
    isLoading: loading,
    refetch,
    error,
  };
};
