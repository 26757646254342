import {
  ProjectChat,
  ProjectPoweredBy,
  ProjectResponseProgressBar,
} from '@/survey-project-response';
import { ComponentProps } from 'react';
import ManagerBrandingPreviewSection from './manager-branding-preview-section';
import {
  DEFAULT_THEYSAID_LOGO_URL,
  SAMPLE_MESSAGES,
  SAMPLE_PROGRESS,
} from './manager-branding.helpers';

export default function ManagerBrandingWidgetPreview({
  accentColor,
  logoUrl,
  avatarUrl,
  showPoweredBy,
}: ComponentProps<typeof ManagerBrandingPreviewSection>) {
  return (
    <div className="animate-fade-in flex flex-col gap-0 rounded-xl bg-gray-50 p-6">
      <img
        src="/img/desktop-browser-header.svg"
        alt="Desktop browser header"
        className="h-9 w-full object-cover"
      />
      <div className="relative -mt-0.5 flex min-h-[36rem] w-full flex-col items-center self-stretch rounded-lg bg-white py-6">
        <div className="absolute bottom-6 right-8 flex min-h-[54rem] origin-bottom-right scale-[0.6] flex-col gap-0 overflow-y-auto rounded-2xl bg-gray-100 px-4 py-6">
          <div className="flex w-full items-center gap-2 rounded-t-2xl border-x border-t border-gray-200 bg-white p-4">
            <img
              src={logoUrl ?? DEFAULT_THEYSAID_LOGO_URL}
              alt="Logo"
              data-default={!logoUrl}
              className="max-h-8 w-full max-w-24 object-contain data-[default=true]:brightness-0"
            />
            <div className="w-full flex-1">
              <ProjectResponseProgressBar
                progress={SAMPLE_PROGRESS}
                accentColor={accentColor ?? undefined}
              />
            </div>
          </div>

          <ProjectChat
            accentColor={accentColor ?? undefined}
            avatarUrl={avatarUrl}
            className="!rounded-t-none"
            messages={SAMPLE_MESSAGES}
            onSubmit={Promise.resolve}
            scrollToEnd={false}
            hideNewLineText
          />
          {showPoweredBy && (
            <div>
              <ProjectPoweredBy />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
