export async function uploadImageToStorage(url: string, file: File) {
  const res = await fetch(url, {
    method: 'PUT',
    body: file,
    headers: {
      'Content-Type': file.type,
    },
  });
  if (!res.ok || !(res.status >= 200 && res.status < 300)) {
    throw new Error('Failed to upload image to storage');
  }
  return res.text();
}
