import type { GetUpgradeDataQuery } from '@/manager-graphql';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/shared-ui';

interface CheckoutSuccessDialogProps {
  planDetails: GetUpgradeDataQuery['companyInfo']['planDetails'];
}

export const CheckoutSuccessDialog = ({
  planDetails,
}: CheckoutSuccessDialogProps) => {
  return (
    <Dialog defaultOpen>
      <DialogContent>
        <DialogTitle className="font-display px-8 py-2 pt-8 text-center text-2xl font-semibold leading-tight">
          Thank you!
        </DialogTitle>
        <DialogDescription className="px-8 text-center text-sm text-gray-400">
          Your payment is complete.
        </DialogDescription>
        <div className="w-full px-8 py-8">
          <DialogClose asChild>
            <Button variant="primary" className="w-full">
              Finish
            </Button>
          </DialogClose>
        </div>
      </DialogContent>
    </Dialog>
  );
};
