import {
  OptionRenderType,
  ProjectType,
  QuestionType,
  V2AddMessageMutation,
  V2GetInsightQuery,
} from '@/survey-graphql';

export const POLL_MESSAGE_UUID = '00000000-0000-0000-0000-000000000002';
export const POLL_MESSAGE_LOADING_UUID = '00000000-0000-0000-0000-000000000003';

export const STORAGE_KEYS = {
  insightId: '@TheySaid:insightId',
} as const;

export function isQuestionType(value: string): value is QuestionType {
  return Object.values(QuestionType).some((type) => type === value);
}

export function isOptionRenderType(
  value: string | null | undefined
): value is OptionRenderType {
  return Object.values(OptionRenderType).some((type) => type === value);
}

export function getQuestionType(value: string): QuestionType {
  if (isQuestionType(value)) {
    return value;
  }
  const firstChar = value.charAt(0).toUpperCase();
  const rest = value.slice(1);
  const alternativeValue = firstChar + rest;
  if (isQuestionType(alternativeValue)) {
    return alternativeValue;
  }
  return QuestionType.OpenEnded;
}

export function parseRating(rating?: string | string[] | null): number | null {
  if (!rating) {
    return null;
  }
  const value = Array.isArray(rating) ? rating[0] : rating;
  const parsedValue = typeof value === 'string' ? parseInt(value, 10) : null;
  if (parsedValue === null) {
    return null;
  }
  return Number.isNaN(parsedValue) ? null : parsedValue;
}

export const SURVEY_RATING_COMPONENT_ID = 'survey-rating';

export function v2UpsertMessage(
  messages: NonNullable<V2GetInsightQuery['insightV2']['messages']>,
  newMessage: V2AddMessageMutation['addMessageV2']['sent']
): NonNullable<V2GetInsightQuery['insightV2']['messages']> {
  const existingMessageIndex = messages.findIndex(
    (message) => message.id === newMessage.id
  );
  if (existingMessageIndex === -1) {
    return [...messages, newMessage];
  }
  return messages.map((m) => (m.id === newMessage.id ? newMessage : m));
}

export const DEFAULT_THEYSAID_LOGO_URL =
  '/survey/img/theysaid-logo-colored-horizontal.svg';

export function isSurveyOrInterview(
  type: ProjectType
): type is ProjectType.Survey | ProjectType.Interview {
  return [ProjectType.Survey, ProjectType.Interview].includes(type);
}
