import { GetProjectQuery, QuestionType } from '@/manager-graphql';

export type Project = GetProjectQuery['project'];

export type Question = NonNullable<Project['questions']>[number];
export enum ImageChoice {
  ImageChoice = 'ImageChoice',
}

export type QuestionTypeWithImageChoice = QuestionType | ImageChoice;

export interface SectionProps {
  project?: Project;
  projectLoading: boolean;
}

export type Tag = NonNullable<Question['tags']>[number];
