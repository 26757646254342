import {
  Arrow,
  Content,
  Provider,
  Root,
  Trigger,
} from '@radix-ui/react-tooltip';
import { cn } from './ui.helpers';

interface TooltipProps {
  children: React.ReactNode;
  content: string | React.ReactNode;
  contentClassName?: string;
  showArrow?: boolean;
}

export default function Tooltip({
  children,
  content,
  contentClassName,
  showArrow = true,
}: TooltipProps) {
  return (
    <Provider>
      <Root>
        <Trigger asChild>{children}</Trigger>
        <Content
          className={cn(
            'w-max max-w-44 rounded-lg bg-zinc-800 px-3 py-2 leading-snug text-white',
            contentClassName
          )}
          sideOffset={4}
        >
          {content}

          {showArrow && <Arrow width={12} height={6} />}
        </Content>
      </Root>
    </Provider>
  );
}
