import { useEffect } from 'react';

import {
  ProjectResponseSendFormRenderer,
  RendererOption,
} from '@/survey-project-response-message-renderers';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { DEFAULT_RENDERER_TYPE } from './project-response-chat.helpers';
import ConversationOverMessageFormRenderer from './project-response-conversation-over-message-form-renderer';
import { messageFormSchema } from './project-response-form.helpers';

type MessageFormValues = z.infer<typeof messageFormSchema>;

interface Props {
  disabled?: boolean;
  onSubmit: (values: MessageFormValues) => Promise<void>;
  accentColor?: string;
  questionType?: RendererOption | null;
  isConversationOver?: boolean;
  hideNewLineText?: boolean;
  onConversationOver?: () => void;
}

export default function ProjectMessageForm({
  disabled,
  onSubmit,
  accentColor,
  questionType,
  isConversationOver,
  hideNewLineText,
  onConversationOver,
}: Props) {
  const {
    formState: { isSubmitting },
    handleSubmit,
    register,
    setFocus,
    watch,
    setValue,
  } = useFormContext<MessageFormValues>();

  useEffect(() => {
    !disabled && setFocus('content');
  }, [disabled, setFocus]);

  useEffect(() => {
    if (questionType !== 'openEnded') {
      setValue('content', '');
    }
  }, [questionType, setValue]);

  const resetAndSubmit = async (data: MessageFormValues) => {
    const content = data.content?.trim();

    if (!content) {
      setValue('content', '');
      return;
    }

    try {
      setValue('content', '');
      await onSubmit(data);
      setTimeout(() => setFocus('content'), 0);
    } catch (error) {
      setValue('content', content);
    }
  };

  const submitOnEnter = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      event.preventDefault();
      handleSubmit(resetAndSubmit)();
    }
  };

  return (
    <form
      onSubmit={handleSubmit(resetAndSubmit)}
      className="flex items-center justify-stretch gap-2 rounded-md bg-white p-1 pl-3 data-[submitting=true]:bg-gray-100"
      data-submitting={isSubmitting}
    >
      {isConversationOver ? (
        <ConversationOverMessageFormRenderer onSubmit={onConversationOver} />
      ) : (
        <ProjectResponseSendFormRenderer
          type={questionType ?? DEFAULT_RENDERER_TYPE}
          isSubmitting={isSubmitting}
          onKeyDown={submitOnEnter}
          disabled={disabled}
          register={register}
          value={watch('content')}
          accentColor={accentColor}
          hideNewLineText={hideNewLineText}
        />
      )}
    </form>
  );
}
