import {
  ESSENTIAL_PRODUCT_CONST,
  PRO_PRODUCT_CONST,
} from './manager-upgrade.const';
import { PremiumFeature } from './manager-upgrade.hooks';

export const formatStripePrice = (price: number | undefined) => {
  if (!price) return 0;
  return price / 100;
};

export const getProductConst = (productCode: string) => {
  if (productCode === 'pro') return PRO_PRODUCT_CONST;
  if (productCode === 'essential') return ESSENTIAL_PRODUCT_CONST;
  return PRO_PRODUCT_CONST;
};

export const getFeatureImage = (feature: PremiumFeature) => {
  return (
    {
      [PremiumFeature.Branding]: '/img/branding.svg',
      [PremiumFeature.ExportInsights]: '/img/exports-preview.svg',
      [PremiumFeature.None]: '/img/branding.svg',
    }[feature] || '/img/branding.svg'
  );
};
