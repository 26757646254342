import { gql } from '../__generated__/gql';

export const UPDATE_OPTION_ASSET = gql(/* GraphQL */ `
  mutation UpdateOptionAsset($input: UpdateOptionAssetInput!) {
    updateOptionAsset(input: $input) {
      id
      name
      description
      url
      createdAt
      updatedAt
    }
  }
`);
