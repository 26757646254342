import {
  CREATE_SIGNED_UPLOAD_URL,
  CreateOrganizationSettingsInput,
  GET_ORGANIZATION_SETTINGS,
  UPDATE_ORGANIZATION_SETTINGS,
  UpdateOrganizationSettingsInput,
} from '@/manager-graphql';
import { useToast } from '@/shared-ui';
import { useMutation, useQuery } from '@apollo/client';
import {
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { uploadImageToStorage } from '@/utilities';
import { useMeasurement } from '@/shared-measurement';

type BrandingFormValuesWithDeleteKeys = {
  avatar: File | null;
  logo: File | null;
  accentColor: string | undefined | null;
  poweredBy: boolean;
  deleteAvatar: boolean;
  deleteLogo: boolean;
};

export type BrandingFormValues = Omit<
  BrandingFormValuesWithDeleteKeys,
  'deleteAvatar' | 'deleteLogo'
>;

export function useManagerBranding() {
  const measurement = useMeasurement();
  const {
    handleSubmit,
    setValue: _setValue,
    watch,
  } = useForm<BrandingFormValuesWithDeleteKeys>({
    defaultValues: {
      avatar: null,
      logo: null,
      accentColor: null,
      poweredBy: true,
      deleteAvatar: false,
      deleteLogo: false,
    },
  });

  const [isSaving, setIsSaving] = useState(false);
  const { addToast } = useToast();
  const customizeSectionRef = useRef<{ reset: () => void }>(null);

  const setValue = (
    key: keyof Omit<
      BrandingFormValuesWithDeleteKeys,
      'deleteAvatar' | 'deleteLogo'
    >,
    value: BrandingFormValuesWithDeleteKeys[typeof key]
  ) => {
    if (key === 'avatar') {
      _setValue('deleteAvatar', false);
    } else if (key === 'logo') {
      _setValue('deleteLogo', false);
    }
    _setValue(key, value);
  };

  const {
    data: orgSettingsData,
    loading: settingsLoading,
    refetch,
  } = useQuery(GET_ORGANIZATION_SETTINGS, {
    onCompleted: (data) => {
      setValue('accentColor', data.orgSettings?.brandColor);
      setValue('poweredBy', !data.orgSettings?.hideTheysaidLogo);
      setValue('avatar', null);
      setValue('logo', null);
      _setValue('deleteAvatar', false);
      _setValue('deleteLogo', false);
    },
  });

  const [createSignedUploadUrl] = useMutation(CREATE_SIGNED_UPLOAD_URL);
  const [updateOrganizationSettings] = useMutation(
    UPDATE_ORGANIZATION_SETTINGS
  );

  const onSubmit = handleSubmit(async (data) => {
    setIsSaving(true);
    try {
      const organizationSettingsInput:
        | CreateOrganizationSettingsInput
        | UpdateOrganizationSettingsInput = {
        hideTheysaidLogo: !poweredBy,
        brandColor: accentColor,
      };
      if (data.logo) {
        const { data: signedUploadUrlData } = await createSignedUploadUrl({
          variables: {
            input: { fileName: data.logo.name, mimeType: data.logo.type },
          },
        });
        if (signedUploadUrlData?.createSignedUploadUrl) {
          organizationSettingsInput.logoUrl =
            signedUploadUrlData?.createSignedUploadUrl.publicUrl;
          await uploadImageToStorage(
            signedUploadUrlData?.createSignedUploadUrl.url,
            data.logo
          );
        }
      } else if (data.deleteLogo) {
        organizationSettingsInput.logoUrl = null;
      }
      if (data.avatar) {
        const { data: signedUploadUrlData } = await createSignedUploadUrl({
          variables: {
            input: { fileName: data.avatar.name, mimeType: data.avatar.type },
          },
        });
        if (signedUploadUrlData?.createSignedUploadUrl) {
          organizationSettingsInput.avatarUrl =
            signedUploadUrlData?.createSignedUploadUrl.publicUrl;
          await uploadImageToStorage(
            signedUploadUrlData?.createSignedUploadUrl.url,
            data.avatar
          );
        }
      } else if (data.deleteAvatar) {
        organizationSettingsInput.avatarUrl = null;
      }
      if (orgSettingsData?.orgSettings) {
        const id = orgSettingsData.orgSettings.id;
        await updateOrganizationSettings({
          variables: {
            input: { ...organizationSettingsInput, id },
          },
        });
      }
      const refetchedData = await refetch();
      setValue('accentColor', refetchedData.data.orgSettings?.brandColor);
      setValue('poweredBy', !refetchedData.data.orgSettings?.hideTheysaidLogo);
      setValue('avatar', null);
      setValue('logo', null);
      _setValue('deleteAvatar', false);
      _setValue('deleteLogo', false);
      addToast({
        title: 'Changes saved successfully',
        type: 'success',
      });
    } catch (e) {
      const errorMessage =
        e instanceof Error ? e.message : 'An unknown error occurred';
      addToast({
        title: errorMessage,
        type: 'error',
      });
    } finally {
      setIsSaving(false);
    }
  });

  const avatar = watch('avatar');
  const deleteAvatar = watch('deleteAvatar');
  const avatarUrl = avatar
    ? URL.createObjectURL(avatar)
    : deleteAvatar
    ? null
    : orgSettingsData?.orgSettings?.avatarUrl ?? null;
  const logo = watch('logo');
  const deleteLogo = watch('deleteLogo');
  const logoUrl = logo
    ? URL.createObjectURL(logo)
    : deleteLogo
    ? null
    : orgSettingsData?.orgSettings?.logoUrl ?? null;
  const accentColor = watch('accentColor');
  const poweredBy = watch('poweredBy');

  const hasChangeHappened = useMemo(() => {
    if (deleteAvatar || deleteLogo) return true;
    const initialBrandColor = orgSettingsData?.orgSettings?.brandColor;
    return (
      initialBrandColor !== accentColor ||
      !!avatar ||
      !!logo ||
      !orgSettingsData?.orgSettings?.hideTheysaidLogo !== poweredBy
    );
  }, [
    accentColor,
    poweredBy,
    logo,
    avatar,
    avatarUrl,
    logoUrl,
    deleteAvatar,
    deleteLogo,
  ]);

  const onCancel = () => {
    setValue('accentColor', orgSettingsData?.orgSettings?.brandColor);
    setValue('poweredBy', !orgSettingsData?.orgSettings?.hideTheysaidLogo);
    setValue('avatar', null);
    setValue('logo', null);
    _setValue('deleteAvatar', false);
    _setValue('deleteLogo', false);
    customizeSectionRef.current?.reset();
  };

  const onDeleteImage = (key: 'avatar' | 'logo') => {
    setValue(key, null);
    if (key === 'avatar') {
      _setValue('deleteAvatar', true);
    } else {
      _setValue('deleteLogo', true);
    }
  };

  useEffect(() => {
    if (measurement) {
      measurement?.track('Page view - Branding');
    }
  }, [measurement]);

  return {
    onSubmit,
    onCancel,
    isSaving,
    settingsLoading,
    avatarUrl,
    logoUrl,
    accentColor,
    poweredBy,
    setValue,
    hasChangeHappened,
    onDeleteImage,
    customizeSectionRef,
  };
}

export const useManagerBrandingCustomizeSection = (
  ref: React.Ref<{
    reset: () => void;
  }>
) => {
  const avatarUploadRef = useRef<{ reset: () => void }>(null);
  const logoUploadRef = useRef<{ reset: () => void }>(null);

  useImperativeHandle(ref, () => ({
    reset: () => {
      avatarUploadRef.current?.reset();
      logoUploadRef.current?.reset();
    },
  }));

  return {
    avatarUploadRef,
    logoUploadRef,
  };
};
