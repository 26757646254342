import {
  POLL_MESSAGE_UUID,
  POLL_MESSAGE_UUID_LOADING,
} from './project-response-message-renderers.helpers';
import ProjectResponsePollResult from './project-response-poll-result';
import { ProjectResponseRankingRendererComponent } from './project-response-ranking-renderer';
import { ProjectResponseRatingScaleRendererComponent } from './project-response-rating-scale-renderer';
import { ProjectResponseOpenEndRendererComponent } from './project-response-renderer-open-end';
import {
  ProjectResponseComponentProps,
  RendererOption,
} from './project-response-renderer.type';
import { ProjectResponseSingleSelectRendererComponent } from './project-response-single-select-renderer';
import { ProjectResponseMultiSelectRendererComponent } from './project-response.multi-select-renderer';

export default function ProjectResponseMessageRenderer({
  type,
  ...rest
}: ProjectResponseComponentProps & {
  type: RendererOption;
}) {
  if (rest.id === POLL_MESSAGE_UUID || rest.id === POLL_MESSAGE_UUID_LOADING) {
    return (
      <ProjectResponsePollResult
        selectedOption={rest.value}
        options={
          rest.options?.map((o) => ({
            id: o.id ?? o.value,
            label: o.label,
            value: o.value,
            count: o.count ?? 0,
          })) ?? []
        }
        loading={rest.id === POLL_MESSAGE_UUID_LOADING}
        type={type}
      />
    );
  }
  switch (type) {
    case 'ranking':
      return <ProjectResponseRankingRendererComponent {...rest} />;
    case 'rating':
      return <ProjectResponseRatingScaleRendererComponent {...rest} />;
    case 'singleChoice':
      return <ProjectResponseSingleSelectRendererComponent {...rest} />;
    case 'multipleChoice':
      return <ProjectResponseMultiSelectRendererComponent {...rest} />;
    case 'openEnded':
    default:
      return <ProjectResponseOpenEndRendererComponent {...rest} />;
  }
}
