import { gql } from '../__generated__/gql';

export const GET_PROJECT_STATS = gql(/* GraphQL */ `
  query GetProjectStats(
    $id: UUID!
    $filters: ProjectStatsFilterInput
    $day1Start: DateTime!
    $day1End: DateTime!
    $day2Start: DateTime!
    $day2End: DateTime!
    $day3Start: DateTime!
    $day3End: DateTime!
    $day4Start: DateTime!
    $day4End: DateTime!
    $day5Start: DateTime!
    $day5End: DateTime!
    $day6Start: DateTime!
    $day6End: DateTime!
    $day7Start: DateTime!
    $day7End: DateTime!
  ) {
    projectStats(id: $id, filters: $filters) {
      id
      title
      totalInsights
      day1Count: totalInsights(filter: { since: $day1Start, until: $day1End })
      day2Count: totalInsights(filter: { since: $day2Start, until: $day2End })
      day3Count: totalInsights(filter: { since: $day3Start, until: $day3End })
      day4Count: totalInsights(filter: { since: $day4Start, until: $day4End })
      day5Count: totalInsights(filter: { since: $day5Start, until: $day5End })
      day6Count: totalInsights(filter: { since: $day6Start, until: $day6End })
      day7Count: totalInsights(filter: { since: $day7Start, until: $day7End })
      channelStats {
        channel
        count
        median
      }
      questions {
        distribution {
          count
          value
        }
        avgScore
        medianScore
        totalInsights
        text
        type
        options {
          label
          value
        }
      }
    }
  }
`);
