export const TAB_VALUES = {
  FORM: 'form',
  SETTINGS: 'settings',
  ALL_RESPONSES: 'responses',
  THEMES: 'themes',
  ANALYTICS: 'analytics',
  ACTION_ITEMS: 'action-items',
} as const;

export const PLAN_CODE_FREE = 'FREE';

export const CONTACT_INFORMATION_TAG_NAME = 'internal:contact';
export const CONTACT_INFORMATION_TAG_COLOR = '#eef2ff';
