import { useQuery } from '@apollo/client';
import { atom, useRecoilState, type AtomEffect } from 'recoil';

import { GET_ORGANIZATION_STATUS } from '@/manager-graphql';

function localStorageEffect<T>(localStorageKey: string) {
  return ({ setSelf, onSet }: Parameters<AtomEffect<T>>[0]) => {
    const savedValue = localStorage.getItem(localStorageKey);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(localStorageKey)
        : localStorage.setItem(localStorageKey, JSON.stringify(newValue));
    });
  };
}

const sidebarState = atom({
  key: 'sidebarState',
  default: 'fixed' as 'collapse' | 'fixed',
  effects: [localStorageEffect('@TheySaid:Sidebar')],
});

export function useSidebar() {
  const [state, setState] = useRecoilState(sidebarState);
  const toggleSidebar = () => {
    setState((prevState) => (prevState === 'fixed' ? 'collapse' : 'fixed'));
  };
  return { sidebarState: state, toggleSidebar };
}

export function useSidebarData() {
  const { data: companyInfoData } = useQuery(GET_ORGANIZATION_STATUS);

  return {
    hasValidOrg: !!companyInfoData?.companyInfo?.website?.trim()?.length,
  };
}
