export const AUTH_EVENTS = {
  SIGN_IN_SUCCESS: 'auth:sign-in-success',
} as const;

export type AuthEventDetail = {
  timestamp: number;
};

export const emitAuthEvent = (eventName: keyof typeof AUTH_EVENTS) => {
  // Store the event in localStorage with a timestamp
  localStorage.setItem(
    AUTH_EVENTS[eventName],
    JSON.stringify({
      timestamp: Date.now(),
    })
  );
};
