import { RendererOption } from './project-response-renderer.type';

interface Props {
  type: RendererOption;
  options: {
    id: string;
    label: string;
    value: string;
    count: number;
  }[];
  selectedOption?: string;
  loading: boolean;
}

export default function ProjectResponsePollResult({
  options,
  selectedOption,
  loading,
  type,
}: Props) {
  if (loading) return null;
  const totalCount = options.reduce((acc, curr) => acc + curr.count, 0);

  return (
    <div className="mb-1 ms-4 inline-flex w-full max-w-[640px] flex-col items-start justify-center gap-2 rounded-lg bg-white p-4 outline outline-1 outline-offset-[-1px] outline-gray-200">
      {options.map((option) => {
        const percentage =
          totalCount > 0 ? Math.round((option.count / totalCount) * 100) : 0;
        const isSelected = option.value === selectedOption;
        const barWidth = totalCount > 0 ? (option.count / totalCount) * 100 : 0;

        return (
          <div
            className="group self-stretch rounded-[9px] bg-gradient-to-r from-rose-400 to-indigo-400 p-[1px] data-[selected=false]:bg-none"
            data-selected={isSelected}
            key={option.id}
          >
            <div className="relative flex h-full w-full items-center justify-center gap-2 overflow-hidden rounded-lg bg-gray-50 p-3">
              <div
                className="animate-fill-in-from-left absolute left-0 top-0 h-12 bg-gradient-to-r from-rose-100 to-indigo-100 transition-all group-data-[selected=true]:from-rose-200 group-data-[selected=true]:to-indigo-200"
                style={{ width: `${barWidth}%` }}
              />
              <div className="z-10 flex-1 font-sans text-gray-900">
                {type === 'rating' ? option.value : option.label}
              </div>
              <div className="z-10 font-sans">{percentage}%</div>
            </div>
          </div>
        );
      })}
      <div className="text-text-primary h-6 w-[528px] justify-start font-['Inter'] leading-normal">
        {totalCount} votes
      </div>
    </div>
  );
}
