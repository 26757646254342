import { faClose} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
} from '@/shared-ui';
import {
  useGatePremiumFeature,
  useStripeProducts,
  useUpgrade,
} from './manager-upgrade.hooks';
import { ManagerPricingItem } from './manager-pricing-item';
import {
  formatStripePrice,
  getFeatureImage,
  getProductConst,
} from './manager-upgrade.helpers';
import { STRIPE_PRODUCT_CODES } from './manager-upgrade.const';

interface UpgradeGatedDialogProps {
  children?: React.ReactNode;
}

export const UpgradeGatedDialog = ({ children }: UpgradeGatedDialogProps) => {
  const { handleUpgrade, isUpgradeLoading } = useUpgrade();
  const {
    premiumFeature,
    showPremiumFeatureDialog,
    handleTogglePremiumFeature,
  } = useGatePremiumFeature();
  const { stripeProducts, stripeProductsLoading } = useStripeProducts(
    premiumFeature.productCode
  );
  const product = stripeProducts?.find(
    (product) => product.metadata?.productCode === premiumFeature.productCode
  );

  const productConst = getProductConst(
    premiumFeature.productCode ?? STRIPE_PRODUCT_CODES.PRO
  );
  const image = getFeatureImage(premiumFeature.feature);

  return (
    <Dialog
      open={showPremiumFeatureDialog}
      onOpenChange={handleTogglePremiumFeature}
    >
      {children}
      <DialogContent className="max-h-[90vh] w-[90vw] max-w-5xl py-8">
        <DialogTitle className="px-12 pb-6">
          <p className="mb-2 text-sm text-gray-400">
            This feature isn't available on your current plan
          </p>
          <p className="font-display text-3xl font-semibold leading-tight">
            Upgrade to{' '}
            <span className="bg-gradient-to-r from-[#FB7185] to-[#818CF8] bg-clip-text text-transparent transition-colors">
              {productConst?.title}
            </span>{' '}
            to unlock {premiumFeature.feature}
          </p>
        </DialogTitle>
        <DialogClose asChild>
          <div className="absolute right-2 top-4">
            <Button variant="ghost">
              <FontAwesomeIcon icon={faClose} />
            </Button>
          </div>
        </DialogClose>

        {stripeProductsLoading ? (
          <div className="flex h-[32rem] items-center justify-center gap-4 overflow-y-auto rounded-b-md">
            <div className="h-10 w-10 animate-spin rounded-full border-b-2 border-t-2 border-gray-900 dark:border-white"></div>
          </div>
        ) : (
          <div className="flex justify-center gap-4 rounded-b-md py-1">
            {product && (
              <ManagerPricingItem
                title={productConst?.title}
                description={productConst?.description}
                price={{
                  amount: formatStripePrice(product?.prices[0].amount),
                  interval: product?.prices[0].interval ?? 'month',
                }}
                features={productConst?.features}
                buttonText="Upgrade"
                buttonOnClick={() => handleUpgrade(product?.prices[0]?.id)}
                isLoading={isUpgradeLoading}
              />
            )}
            <img src={image} alt="Arc" />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};