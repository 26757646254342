import { ProjectResponseComponentProps } from './project-response-renderer.type';
import ReactPlayer from 'react-player';
import { useState } from 'react';

interface ProjectResponseAssetItemProps {
  asset: NonNullable<ProjectResponseComponentProps['assets']>[number];
}

export function ProjectResponseAssetItem({
  asset,
}: ProjectResponseAssetItemProps) {
  const [hasVideoError, setHasVideoError] = useState(false);
  const [hasImageError, setHasImageError] = useState(false);

  const isImage =
    /\.(jpg|jpeg|png|gif|webp|svg|avif|bmp|ico|tiff)$/i.test(asset.url) ||
    /(?:unsplash\.com|imgur\.com|cloudinary\.com|images\.pexels\.com|img\.youtube\.com|i\.ibb\.co|imagekit\.io|res\.cloudinary\.com|imgbb\.com|drive\.google\.com.*\/[^&]*\.(jpg|jpeg|png|gif|webp|svg|avif))/i.test(
      asset.url
    );

  const renderMedia = () => {
    if (hasImageError || hasVideoError) {
      return null;
    }

    if (isImage) {
      return (
        <img
          src={asset.url}
          alt={asset.name || 'Asset image'}
          className="mb-2 max-h-96 w-full max-w-72 rounded-lg object-cover"
          onError={() => setHasImageError(true)}
        />
      );
    }

    return (
      <ReactPlayer
        url={asset.url}
        controls
        muted
        width="100%"
        height="100%"
        style={{
          aspectRatio: '16/9',
          maxWidth: '100%',
          margin: '5px 0',
          maxHeight: '344px',
        }}
        onError={() => setHasVideoError(true)}
      />
    );
  };

  return (
    <div className="not-prose asset-item-rounded flex w-full min-w-72 items-center justify-center sm:px-16">
      {renderMedia()}
    </div>
  );
}
