import { ComponentProps } from 'react';
import RatingScaleSelect from './project-response-rating-scale-select';

type RatingScaleSelectProps = ComponentProps<typeof RatingScaleSelect>;

export const renderValue = (
  option: RatingScaleSelectProps['options'][0],
  optionRenderType: RatingScaleSelectProps['optionRenderType'],
  options: RatingScaleSelectProps['options']
) => {
  switch (optionRenderType) {
    case 'Emoji':
      return getEmoji(+option.value, options.length);
    case 'Star':
      return '★';
    default:
      return option.value;
  }
};

export const getEmoji = (value: number, total: number) => {
  // For 3-option scale
  const threeOptionEmojis: Record<number, string> = {
    1: '😫',
    2: '😐',
    3: '😄',
  };

  // For 5-option scale
  const fiveOptionEmojis: Record<number, string> = {
    1: '😫',
    2: '🙁',
    3: '😐',
    4: '🙂',
    5: '😄',
  };

  // Select emoji map based on total options
  const emojiMap = total === 3 ? threeOptionEmojis : fiveOptionEmojis;
  return emojiMap[value] || value.toString();
};
