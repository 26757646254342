export const colorMap: Record<string, { border: string; background: string }> =
  {
    '1': { border: 'border-red-300', background: 'bg-red-50' },
    '2': { border: 'border-orange-300', background: 'bg-orange-50' },
    '3': { border: 'border-yellow-300', background: 'bg-yellow-50' },
    '4': { border: 'border-lime-300', background: 'bg-lime-50' },
    '5': { border: 'border-green-300', background: 'bg-green-50' },
  };

export const isDevelopment = () => {
  if (typeof import.meta.env !== 'undefined') {
    // In a Vite environment (React app)
    return import.meta.env.DEV;
  } else if (typeof process !== 'undefined') {
    // In a Node environment (Next.js)
    return process.env.NODE_ENV === 'development';
  }

  return false;
};

export const getStarIconUrl = () => {
  if (isDevelopment()) {
    return 'https://static.dev.theysaid.io/images/star-outlined.svg';
  }

  return 'https://static.theysaid.io/images/star-outlined.svg';
};
