import { useMutation, useQuery } from '@apollo/client';
import { endOfMonth, startOfMonth } from 'date-fns';
import { atom, useRecoilState } from 'recoil';
import { useCallback } from 'react';

import {
  CREATE_CHECKOUT_SESSION,
  CREATE_CUSTOMER_PORTAL_SESSION,
  GET_STRIPE_PRODUCTS,
  GET_UPGRADE_DATA,
} from '@/manager-graphql';
import {
  STRIPE_PRODUCT_CODES,
  StripeProductCode,
} from './manager-upgrade.const';
import { useMeasurement } from '@/shared-measurement';
import { PLAN_CODE_FREE } from '@/shared-manager-project';

const currentDate = new Date();
const since = startOfMonth(currentDate).toISOString();
const until = endOfMonth(currentDate).toISOString();

const upgradeState = atom({
  key: 'upgradeState',
  default: {
    showUpgrade: false,
  },
});

export enum PremiumFeature {
  None = 'none',
  Branding = 'branding',
  ExportInsights = 'exporting responses',
}

const premiumFeatureDialogState = atom<{
  feature: PremiumFeature;
  productCode: StripeProductCode | undefined;
}>({
  key: 'premiumFeatureDialogState',
  default: {
    feature: PremiumFeature.None,
    productCode: undefined,
  },
});

const useCreateCustomerPortalSession = () =>
  useMutation(CREATE_CUSTOMER_PORTAL_SESSION);

export const useStripeProducts = (productCode?: string) => {
  const { data, loading } = useQuery(GET_STRIPE_PRODUCTS, {
    variables: {
      filter: {
        name: productCode ?? undefined,
      },
    },
  });
  return {
    stripeProducts: data?.stripeProducts?.products,
    stripeProductsLoading: loading,
  };
};

export const useUpgradeBilling = () => {
  const measurement = useMeasurement();
  const [createCustomerPortalSession] = useCreateCustomerPortalSession();
  const [createCheckoutSession, { loading: checkoutSessionLoading }] =
    useMutation(CREATE_CHECKOUT_SESSION);
  const handleCustomerPortalSession = async () => {
    const response = await createCustomerPortalSession();
    measurement?.track('Page view - Billing');
    if (response?.data?.createCustomerPortalSession?.url) {
      window.location.href = response?.data?.createCustomerPortalSession?.url;
    }
  };
  const handleCheckoutSession = async (priceId: string) => {
    const response = await createCheckoutSession({ variables: { priceId } });
    if (response?.data?.createCheckoutSession?.url) {
      window.location.href = response?.data?.createCheckoutSession?.url;
    }
  };
  return {
    handleCustomerPortalSession,
    handleCheckoutSession,
    checkoutSessionLoading,
  };
};

export const useUpgrade = () => {
  const { data, loading } = useQuery(GET_UPGRADE_DATA, {
    variables: {
      insightsCountInput: {
        since,
        until,
      },
    },
  });
  const [state, setState] = useRecoilState(upgradeState);
  const {
    handleCustomerPortalSession,
    handleCheckoutSession,
    checkoutSessionLoading,
  } = useUpgradeBilling();

  const planDetails = data?.companyInfo?.planDetails;

  const handleToggleUpgrade = () => {
    if (planDetails?.planCode !== PLAN_CODE_FREE) {
      handleCustomerPortalSession();
      return;
    }

    setState({
      showUpgrade: !state?.showUpgrade,
    });
  };

  const handleUpgrade = (priceId: string | undefined) => {
    if (priceId) {
      handleCheckoutSession(priceId);
    }
  };

  return {
    planDetails,
    stripeCustomerId: data?.companyInfo?.stripeCustomerId,
    showUpgrade: state?.showUpgrade,
    handleToggleUpgrade,
    loading,
    handleUpgrade,
    isUpgradeLoading: checkoutSessionLoading,
  };
};

export const useGatePremiumFeature = () => {
  const [premiumFeatureState, setPremiumFeatureState] = useRecoilState(
    premiumFeatureDialogState
  );

  const handleTogglePremiumFeature = useCallback(
    (
      featureOrOpen: PremiumFeature | boolean,
      productCode?: StripeProductCode
    ) => {
      if (typeof featureOrOpen === 'boolean') {
        setPremiumFeatureState({
          feature: featureOrOpen
            ? premiumFeatureState.feature
            : PremiumFeature.None,
          productCode: productCode ?? STRIPE_PRODUCT_CODES.PRO,
        });
      } else {
        setPremiumFeatureState({
          feature: featureOrOpen,
          productCode: productCode ?? STRIPE_PRODUCT_CODES.PRO,
        });
      }
    },
    [premiumFeatureState.feature, setPremiumFeatureState]
  );

  return {
    showPremiumFeatureDialog:
      premiumFeatureState.feature !== PremiumFeature.None,
    premiumFeature: premiumFeatureState,
    handleTogglePremiumFeature,
  };
};
