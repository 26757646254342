import { ContactInformationTiming, ProjectType } from '@/manager-graphql';
import { z } from 'zod';

export const URL_PATTERN =
  /^(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?(\?.*)?$/;

export const updateProjectSettingsSchema = z
  .object({
    askContactInformation: z.enum([
      ContactInformationTiming.Early,
      ContactInformationTiming.Late,
      ContactInformationTiming.Naturally,
    ]),
    redirectUrl: z
      .string()
      .nullable()
      .transform((value) => {
        if (!value) return '';
        return value?.toLowerCase().startsWith('http')
          ? value
          : `https://${value}`;
      })
      .refine(
        (transformedValue) =>
          transformedValue === '' ||
          URL_PATTERN.test(transformedValue?.toLowerCase()),
        {
          message: 'Invalid url. Please enter a valid url.',
        }
      )
      .nullish(),
    introText: z.string().nullish(),
    thankyouText: z.string().nullish(),
    learningGoal: z.string().nullish(),
    totalInsights: z.number().nullish(),
  })
  .strip();

export type UpdateProjectSettingsValues = z.infer<
  typeof updateProjectSettingsSchema
>;

export function isSurveyOrInterview(
  type: ProjectType
): type is ProjectType.Survey | ProjectType.Interview {
  return [ProjectType.Survey, ProjectType.Interview].includes(type);
}
