import { gql } from '../__generated__/gql';

export const CREATE_QUESTION_ASSET = gql(/* GraphQL */ `
  mutation CreateQuestionAsset($input: CreateQuestionAssetInput!) {
    createQuestionAsset(input: $input) {
      id
      name
      url
      description
      createdAt
      updatedAt
    }
  }
`);
