import { ProjectType } from '@/manager-graphql';
import { createContext, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { TAB_VALUES } from './shared-manager-project.consts';
import { Project } from './shared-manager-project.types';

export const tabSchema = z.union([
  z.literal(TAB_VALUES.FORM),
  z.literal(TAB_VALUES.SETTINGS),
  z.literal(TAB_VALUES.ALL_RESPONSES),
  z.literal(TAB_VALUES.THEMES),
  z.literal(TAB_VALUES.ANALYTICS),
  z.literal(TAB_VALUES.ACTION_ITEMS),
]);

const ProjectContext = createContext<{
  project: Project | null;
  projectLoading: boolean;
}>({
  project: null,
  projectLoading: false,
});

export function useProjectAndTab() {
  const { project, projectLoading } = useContext(ProjectContext);
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab') ?? TAB_VALUES.FORM;
  return { project, projectLoading, tab };
}

export function useTab() {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleChange = (tab: string) => {
    const parsedTab = tabSchema.parse(tab);
    setSearchParams((prev) => {
      prev.set('tab', parsedTab);
      return prev;
    });
  };

  const parsedTab = tabSchema.safeParse(searchParams.get('tab'));
  const tab = parsedTab.success ? parsedTab.data : TAB_VALUES.FORM;

  return {
    onTabChange: handleChange,
    tab,
  };
}

export const useProjectType = (project: Project | null) => {
  const [params] = useSearchParams();
  if (project) {
    if (project.isPoll && project.type === ProjectType.Pulse)
      return ProjectType.Poll;
    return project.type;
  } else if (params.get('project-type') === ProjectType.Survey)
    return ProjectType.Survey;
  else if (params.get('project-type') === ProjectType.Interview)
    return ProjectType.Interview;
  else if (params.get('project-type') === ProjectType.Poll)
    return ProjectType.Poll;
  return ProjectType.Pulse;
};
