import { faGripDotsVertical } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forwardRef, Ref } from 'react';

interface SurveyResponseSelectItemProps {
  value: string;
  tag?: string;
  selected?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  isDragAndDrop?: boolean;
  label: string;
}

const ProjectResponseSelectItem = forwardRef(
  (
    {
      tag,
      selected = false,
      onClick,
      disabled,
      isDragAndDrop,
      label,
    }: SurveyResponseSelectItemProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    return (
      <div
        data-type={isDragAndDrop ? 'drag-and-drop' : 'select'}
        data-selected={selected}
        className="flex min-h-10 w-full items-center justify-center rounded-[9px] bg-gradient-to-r from-rose-100 to-indigo-100 hover:from-rose-200 hover:to-indigo-200 active:from-rose-400 active:to-indigo-400 data-[selected=true]:from-rose-400 data-[selected=true]:to-indigo-400 data-[type=drag-and-drop]:active:rotate-1"
      >
        <button
          onClick={onClick}
          className="relative mx-auto my-auto flex h-[calc(100%-2px)] w-[calc(100%-2px)] items-center justify-between gap-4 self-stretch rounded-lg bg-gradient-to-r from-rose-50 to-indigo-50 px-3 transition-all hover:from-rose-100 hover:to-indigo-100 active:from-rose-100 active:to-indigo-100 active:ring-2 active:ring-sky-300 data-[selected=true]:from-rose-200 data-[selected=true]:to-indigo-200 data-[type=drag-and-drop]:active:from-rose-200 data-[type=drag-and-drop]:active:to-indigo-200 data-[type=drag-and-drop]:active:ring-0"
          data-selected={selected}
          data-type={isDragAndDrop ? 'drag-and-drop' : 'select'}
          disabled={disabled}
          ref={ref}
        >
          <div className="flex items-center gap-2">
            {isDragAndDrop ? (
              <FontAwesomeIcon
                icon={faGripDotsVertical}
                className="h-4 w-4 text-gray-400"
              />
            ) : null}
            <div className="text-start font-sans text-gray-900">{label}</div>
          </div>
          <div className="inline-flex w-5 flex-col items-center justify-center gap-2 rounded border border-gray-200 bg-gray-50 px-1 py-0.5">
            <div className="w-4 text-center font-sans text-xs leading-none text-gray-900">
              {tag}
            </div>
          </div>
        </button>
      </div>
    );
  }
);

export default ProjectResponseSelectItem;
