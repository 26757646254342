import { gql } from '../__generated__/gql';

export const LIST_DOWNLOADS = gql(/* GraphQL */ `
  query ListDownloads($filter: ProjectInsightsDownloadsFilterInput!) {
    downloads(filter: $filter) {
      id
      createdAt
      expiresAt
      size
      status
      url
    }
  }
`);
