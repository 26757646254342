import { gql } from '../__generated__/gql';

export const GET_POLL_RESULT = gql(/* GraphQL */ `
  query GetPollResult($id: UUID!) {
    pollResults(id: $id) {
      count
      value
    }
  }
`);
