import { useLocation } from 'react-router-dom';

import { UPDATE_COMPANY_INFO, UPDATE_LAST_LOGIN } from '@/manager-graphql';
import { useOnboarding } from '@/manager-onboarding';
import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { AUTH_EVENTS } from '@/manager-auth';
import { useAuth } from '@workos-inc/authkit-react';

export function useHomeWrapper() {
  const { organizationId } = useAuth();
  const [updateLastLogin] = useMutation(UPDATE_LAST_LOGIN);
  const [updateCompanyInfo] = useMutation(UPDATE_COMPANY_INFO);
  const location = useLocation();
  const { pathname } = location;
  const companyOnboardingPath = '/home/onboarding' as const;
  const homePath = '/home' as const;
  const { companyInfo, isLoading } = useOnboarding();
  const shouldRedirectToOnboard =
    !pathname.startsWith(companyOnboardingPath) &&
    !companyInfo?.hasOnboarded &&
    (companyInfo?.insightsCount === 0 || !companyInfo?.insightsCount) &&
    !isLoading;

  const shouldRedirectToHome =
    location.pathname.startsWith('/home/onboarding') &&
    !isLoading &&
    (companyInfo?.hasOnboarded ||
      (companyInfo?.insightsCount && companyInfo?.insightsCount > 0));

  useEffect(() => {
    const signInEvent = localStorage.getItem(AUTH_EVENTS.SIGN_IN_SUCCESS);

    if (signInEvent && organizationId) {
      updateLastLogin();
      // Clear the event after handling it
      localStorage.removeItem(AUTH_EVENTS.SIGN_IN_SUCCESS);
    }
  }, [updateLastLogin, organizationId]);

  useEffect(() => {
    if (
      companyInfo &&
      !companyInfo.hasOnboarded &&
      !isLoading &&
      companyInfo.insightsCount &&
      companyInfo.insightsCount > 0
    ) {
      updateCompanyInfo({
        variables: {
          input: {
            id: companyInfo.id,
            hasOnboarded: true,
          },
        },
      });
    }
  }, [companyInfo, isLoading, updateCompanyInfo]);

  return {
    shouldRedirectToOnboard,
    companyOnboardingPath,
    shouldRedirectToHome,
    homePath,
    isLoading,
  };
}
