import { useAuth } from '@workos-inc/authkit-react';
import { Navigate } from 'react-router-dom';

export default function Landing() {
  const { user } = useAuth();

  if (user) {
    return <Navigate to="home" />;
  } else {
    if (['evo.dev.theysaid.io', 'localhost'].includes(window.location.hostname)) {
      return <Navigate to="login" />;
    } else {
      window.location.href = 'https://theysaid.io';
    }
  }

  return <div></div>;
}
