import { gql } from '../__generated__/gql';

export const GET_PROJECT_INSIGHTS = gql(/* GraphQL */ `
  query GetProjectInsights(
    $first: Int
    $after: Cursor
    $before: Cursor
    $orderBy: InsightOrder
    $filter: InsightV2ListFilterInput!
  ) {
    insightsV2(
      first: $first
      after: $after
      before: $before
      orderBy: $orderBy
      filter: $filter
    ) {
      items {
        id
        contact {
          email
          firstName
          lastName
          phone
        }
        actionItems {
          id
          createdAt
          status
          text
          type
        }
        chatSize
        createdAt
        convoDepth
        firstUserResponse {
          id
          createdAt
          metadata {
            type
            options {
              value
              label
            }
          }
          text
          sender
        }
        msgRead
        insightRead
        progress
        shareId
        signal
        summaryTitle
        project {
          id
          title
          type
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        total
      }
    }
  }
`);
