import { gql } from '../__generated__/gql';

export const V2_ADD_MESSAGE = gql(/* GraphQL */ `
  mutation V2AddMessage($input: AddMessageV2Input!) {
    addMessageV2(input: $input) {
      sent {
        id
        content
        createdAt
        sender
        optionRenderType
        assets {
          id
          name
          url
          description
          createdAt
          updatedAt
        }
        metadata {
          isConversationOver
          multipleSelect
          options {
            label
            value
            assets {
              id
              name
              url
              description
              createdAt
              updatedAt
            }
          }
          required
          type
        }
      }
      reply {
        id
        content
        createdAt
        sender
        optionRenderType
        assets {
          id
          name
          url
          description
          createdAt
          updatedAt
        }
        metadata {
          isConversationOver
          multipleSelect
          options {
            label
            value
            assets {
              id
              name
              url
              description
              createdAt
              updatedAt
            }
          }
          required
          type
        }
      }
      surveyProgress
    }
  }
`);
