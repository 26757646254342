import * as Sentry from '@sentry/react';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';

import App from './app/app';

const isDev = window.location.host.includes('dev');
const isLocalhost = window.location.hostname === 'localhost';

Sentry.init({
  dsn: 'https://260f4a3cefd740e7f7b5477c69c64b32@o358591.ingest.us.sentry.io/4508875205378048',
  environment: isDev ? 'dev' : 'prod',
  enabled: !isLocalhost,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  beforeSend(event, hint) {
    const exception = hint.originalException as Error & {
      extraInfo?: Record<string, unknown>;
    };

    if (exception?.extraInfo) {
      event.extra = {
        ...event.extra,
        ...exception.extraInfo,
      };
    }

    return event;
  },
  // Performance Monitoring
  tracesSampleRate: isDev ? 0.1 : 1.0,
  tracePropagationTargets: [/^https:\/\/.*.theysaid\.io\/graphql/],
  // Session Replay
  replaysSessionSampleRate: isDev ? 1.0 : 0.1,
  replaysOnErrorSampleRate: isDev ? 0.1 : 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
