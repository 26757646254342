import {
  faImage,
  faInputPipe,
  faListUl,
  faRankingStar,
  faScaleBalanced,
  type IconDefinition,
} from '@fortawesome/pro-light-svg-icons';
import {
  FontAwesomeIcon,
  type FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';

import { QuestionType } from '@/manager-graphql';
import {
  ImageChoice,
  QuestionTypeWithImageChoice,
} from './shared-manager-project.types';

const QUESTION_TYPE_ICON: Record<QuestionTypeWithImageChoice, IconDefinition> =
  {
    [QuestionType.MultipleChoice]: faListUl,
    [QuestionType.OpenEnded]: faInputPipe,
    [QuestionType.Ranking]: faRankingStar,
    [QuestionType.Rating]: faScaleBalanced,
    [ImageChoice.ImageChoice]: faImage,
  };

interface Props extends Omit<FontAwesomeIconProps, 'icon'> {
  questionType: QuestionTypeWithImageChoice;
  required?: boolean;
}

const QuestionTypeIcon = ({ questionType, required, ...props }: Props) => {
  return (
    <div className="relative">
      <FontAwesomeIcon icon={QUESTION_TYPE_ICON[questionType]} {...props} />
      {required && (
        <div className="absolute -right-1 top-0.5 h-1.5 w-1.5 rounded-full bg-red-500"></div>
      )}
    </div>
  );
};

export default QuestionTypeIcon;
