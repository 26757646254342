import { useAuth } from '@workos-inc/authkit-react';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Redirecting from './auth.redirecting';

export default function SignInPage() {
  const { signIn, user } = useAuth();
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const context = params.get('context') ?? undefined;

  useEffect(() => {
    if (user) {
      navigate(-1);
    } else {
      signIn({ context, state: { returnTo: 'home' } });
    }
  }, [context, signIn, user, navigate]);

  return <Redirecting />;
}
