import { gql } from '../__generated__/gql';

export const CREATE_OPTION_ASSET = gql(/* GraphQL */ `
  mutation CreateOptionAsset($input: CreateOptionAssetInput!) {
    createOptionAsset(input: $input) {
      id
      name
      description
      url
      createdAt
      updatedAt
    }
  }
`);
