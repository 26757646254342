import { faCircleCheck, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ManagerPricingItemProps {
  title: string;
  description: string;
  price: {
    amount: number;
    interval: string;
  };
  features: string[];
  buttonText?: string;
  buttonOnClick: () => void;
  isLoading?: boolean;
}

export const ManagerPricingItem = ({
  title,
  description,
  price,
  features,
  buttonText,
  buttonOnClick,
  isLoading,
}: ManagerPricingItemProps) => {
  return (
    <div className="flex max-w-[300px] flex-col rounded-2xl border border-gray-200 px-6 py-4">
      <div className="mb-4 border-b border-gray-200 pb-4">
        <div className="text-2xl font-medium  text-zinc-900">{title}</div>
        <div className="text-gray-500">{description}</div>
      </div>
      <div className="flex flex-col gap-4 pb-8">
        <div className="flex flex-col gap-4 self-stretch">
          {features.map((feature) => (
            <div key={feature} className="flex gap-2 ">
              <FontAwesomeIcon
                icon={faCircleCheck}
                size="lg"
                className="mt-1"
              />
              <div className="text-gray-500">{feature}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-5 self-stretch">
        <div className="text-gray-500">
          Only ${price?.amount} /{price?.interval}
        </div>
        <button
          className="inline-flex h-10 items-center justify-center gap-2 self-stretch overflow-hidden rounded-lg bg-gray-800 p-4"
          onClick={buttonOnClick}
          disabled={isLoading}
        >
          <div className="text-sm font-semibold text-gray-100">
            {isLoading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              buttonText || 'Upgrade'
            )}
          </div>
        </button>
      </div>
    </div>
  );
};
