import { ProjectResponseSendComponentProps } from '@/survey-project-response-message-renderers';

export default function ConversationOverMessageFormRenderer({
  onSubmit,
}: ProjectResponseSendComponentProps) {
  return (
    <button
      onClick={onSubmit}
      className="flex h-12 w-full flex-1 items-center justify-center rounded-lg bg-gradient-to-b from-gray-800 to-gray-900 px-3 py-2 text-white"
    >
      I'm done
    </button>
  );
}
